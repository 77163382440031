import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../Home/Home.css";
import "../About/About.css";
import "../Movies/Movies.css";
import "./Recipes.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { recipe_details } from '../../assets/recipes/recipe_details';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import twitter from "../../assets/images/twitter.png";

function Recipes() {
    const { recipe } = useParams();  // Get the recipe parameter from the route

    const [state, setState] = useState({
        recipe: {
            "name": "test",
            "recipe": "test-recipe",
            "date": "11/Jan/22",
            "reviewer": { name: "Test", twitter: "test twitter" },
            "wifereview": "test",
            "kidsreview": "test",
            "image_1": { image: "dune001.jpg", text: "", alt: "" },
            "image_2": { image: "dune001.jpg", text: "" },
            "image_3": "",
            "image_4": "",
            "image_5": "",
            "image_6": "",
            "paragraph_1": "test",
            "paragraph_2": "test",
            "paragraph_3": "test",
            "paragraph_5": "test ",
            "paragraph_6": "test",
            "ingredients": ["test", "test"],
            "image_sources": ""
        },
        recipes: [],
        allRecipes: true,
        url: "",
        loadedImages: {}, // Store dynamic image imports
    });

    useEffect(() => {
        deleteAllCookies();

        if (recipe === "all") {
            setState(prevState => ({ ...prevState, allRecipes: true }));
            loadAllRecipeImages(recipe_details);
        } else {
            const result = recipe_details.filter(o => o.recipe === recipe);
            if (result.length > 0) {
                setState(prevState => ({
                    ...prevState,
                    recipe: result[0],
                    allRecipes: false,
                    url: `https://twitter.com/intent/tweet?text=Check%20out%20this%20recipe%20on%20https://NOadsHERE.com/recipes/${recipe}`,
                }));
                loadImages(result[0]);
            }
        }
        setState(prevState => ({ ...prevState, recipes: recipe_details }));
    }, [recipe]);

    const loadImages = (recipe) => {
        const imageKeys = ['image_1', 'image_2', 'image_3', 'image_4', 'image_5', 'image_6'];
        imageKeys.forEach((key) => {
            if (recipe[key] && recipe[key].image) {
                import(`../../assets/images/${recipe[key].image}`)
                    .then((image) => {
                        setState(prevState => ({
                            ...prevState,
                            loadedImages: { ...prevState.loadedImages, [key]: image.default }
                        }));
                    })
                    .catch((err) => console.error("Error loading image:", err));
            }
        });
    };

    const loadAllRecipeImages = (recipes) => {
        recipes.forEach((recipe) => {
            if (recipe.image_1 && recipe.image_1.image) {
                import(`../../assets/images/${recipe.image_1.image}`)
                    .then((image) => {
                        setState(prevState => ({
                            ...prevState,
                            loadedImages: {
                                ...prevState.loadedImages,
                                [recipe.recipe]: image.default // Use recipe identifier to store image
                            }
                        }));
                    })
                    .catch((err) => console.error("Error loading image:", err));
            }
        });
    };

    const deleteAllCookies = () => {
        const cookies = document.cookie.split(";");
        cookies.forEach(cookie => {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        });
        localStorage.clear();
        sessionStorage.clear();
    };

    const filterArray = (e) => {
        const searched = recipe_details.filter(({ recipe }) => recipe.includes(e.target.value.toLowerCase()));
        setState(prevState => ({ ...prevState, recipes: searched }));
    };

    return (
        <div className="divmor">
            <Container>
                <Row className="movieRow">
                    {!state.allRecipes && (
                        <div>
                            <Col sm={12} lg={8}>
                                <div className="movieDiv">
                                    <h1 className="darkGrey">
                                        {state.recipe.name}<br />
                                        <span className="movieDate">{state.recipe.date} - {state.recipe.reviewer.twitter && <a href={state.recipe.reviewer.twitter} rel="noopener noreferrer" target="_blank">{state.recipe.reviewer.name}</a>}
                                            {!state.recipe.reviewer.twitter && state.recipe.reviewer.name}
                                        </span>
                                    </h1>
                                    {/* Main Recipe Image */}
                                    {state.loadedImages.image_1 && (
                                        <img className="movieImage"
                                             src={state.loadedImages.image_1}
                                             alt={state.recipe.image_1.text} />
                                    )}
                                    <figcaption className="fig"><h4>{state.recipe.image_1.text}</h4></figcaption>
                                    <br />
                                    <p className="movieBlurb">{state.recipe.paragraph_1}</p>
                                    <p className="movieBlurb"><strong>Ingredients:</strong></p>
                                    {state.recipe.ingredients.map(item => (
                                        <p className="movieBlurb" key={item}>- {item}</p>
                                    ))}
                                    <p className="movieBlurb"><strong>Method:</strong></p>
                                    <p className="movieBlurb">{state.recipe.paragraph_2}</p>

                                    {state.loadedImages.image_2 && (
                                        <div>
                                            <img className="movieImage"
                                                 src={state.loadedImages.image_2}
                                                 alt={state.recipe.image_2.text} />
                                            <figcaption className="fig"><h4>{state.recipe.image_2.text}</h4></figcaption>
                                            <br />
                                        </div>
                                    )}
                                    <p className="movieBlurb">{state.recipe.paragraph_3}</p>
                                    <p className="movieBlurb">{state.recipe.paragraph_4}</p>
                                    {state.loadedImages.image_3 && (
                                        <div>
                                            <img className="movieImage"
                                                 src={state.loadedImages.image_3}
                                                 alt={state.recipe.image_3.text} />
                                            <figcaption className="fig"><h4>{state.recipe.image_3.text}</h4></figcaption>
                                            <br />
                                        </div>
                                    )}
                                    <p className="movieBlurb">{state.recipe.paragraph_5}</p>
                                    <p className="movieBlurb">{state.recipe.paragraph_6}</p>
                                    {state.loadedImages.image_4 && (
                                        <div>
                                            <img className="movieImage"
                                                 src={state.loadedImages.image_4}
                                                 alt={state.recipe.image_4.text} />
                                            <figcaption className="fig"><h4>{state.recipe.image_4.text}</h4></figcaption>
                                            <br />
                                        </div>
                                    )}
                                    <p className="movieBlurb">{state.recipe.paragraph_7}</p>
                                    <p className="movieBlurb">{state.recipe.paragraph_8}</p>
                                    <p className="movieBlurb">{state.recipe.paragraph_9}</p>
                                    {state.loadedImages.image_5 && (
                                        <div>
                                            <img className="movieImage"
                                                 src={state.loadedImages.image_5}
                                                 alt={state.recipe.image_5.text} />
                                            <figcaption className="fig"><h4>{state.recipe.image_5.text}</h4></figcaption>
                                            <br />
                                        </div>
                                    )}
                                    <p className="movieBlurb">{state.recipe.paragraph_10}</p>
                                    <p className="movieBlurb">{state.recipe.paragraph_11}</p>
                                    <p className="movieBlurb"><strong>Share this recipe on:</strong></p>
                                    <a className="twitter-share-button" rel="noopener noreferrer" target="_blank"
                                       href={state.url + state.recipe.recipe}>
                                        <img className="social shareImage" src={twitter} alt="Twitter logo"/></a>
                                    <br/>
                                    <br/>
                                    <h4 className="shareTextTV"><strong>Sharing on Facebook coming soon.</strong></h4>
                                    <br/>
                                    <br/>
                                </div>
                            </Col>
                        </div>
                    )}

                    {state.allRecipes && (
                        <div>
                            <Col sm={12} lg={12}>
                                <div className="movieDiv">
                                    <Row>
                                        <Col sm={4} lg={8}>
                                            <h1 className="darkGrey">Recipes</h1>
                                            <h4>Recipes are the main reason we built this site. Have you ever wanted to look up a quick recipe on your phone, only to find every site covered in ads, videos and unnecessary backstories so they can add more ads in? Us too, so this part of the site has no ads, just recipes!</h4>
                                            <br />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4} lg={4}>
                                            <Form.Group>
                                                <Form.Control type="input" id="searchId" placeholder="Search recipes" onChange={filterArray} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="allRow">
                                        {state.recipes.map((item) => (
                                            <Col className="tvCol" sm={4} lg={4} key={item.recipe}>
                                                <div className="bgImageDiv" style={{ backgroundImage: state.loadedImages[item.recipe] ? `url(${state.loadedImages[item.recipe]})` : 'none', backgroundSize: "cover" }}>
                                                    <Card className="contentCard tv">
                                                        <a className="cardLink" href={`/recipes/${item.recipe}`}>
                                                            <p className="cardLinkText">{item.name}</p>
                                                        </a>
                                                    </Card>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </Col>
                        </div>
                    )}
                </Row>
            </Container>
            <div className="footerAbout">
                <a className="footerLinks" href="/">home</a>
                <a className="footerLinks" href="/tv/all">tv</a>
                <a className="footerLinks" href="/movies/all">movies</a>
                <a className="footerLinks" href="/recipes/all">recipes</a>
                <a className="footerLinks" href="/about/">about</a>
            </div>
        </div>
    );
}

export default Recipes;
