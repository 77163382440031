import React, { useState, useEffect } from "react";
import { useParams /*, useNavigate*/ } from "react-router-dom";
import "../Home/Home.css";
import "../About/About.css";
import "../Movies/Movies.css";
import "../Recipes/Recipes.css";
import "../TV/TV.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { book_details } from '../../assets/books/book_details';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
//import twitter from "../../assets/images/twitter.png";

function Books() {
    const { book } = useParams(); // get the book param from URL
    //const navigate = useNavigate(); // use navigate to programmatically change the route
    const [state, setState] = useState({
        book: {
            name: "test",
            book: "test-recipe",
            date: "11/Jan/22",
            reviewer: { name: "Test", twitter: "test twitter" },
            image_1: "dune001.jpg",
            image_2: "dune001.jpg",
            image_3: "dune001.jpg",
            image_4: "dune001.jpg",
            image_5: "dune001.jpg",
            image_6: "dune001.jpg",
            paragraph_1: "test",
            paragraph_2: "test",
            paragraph_3: "test",
            paragraph_5: "test ",
            paragraph_6: "test",
            paragraph_7: "",
            paragraph_8: "",
            paragraph_9: "",
            paragraph_10: "",
            darkTitle: false,
        },
        books: [],
        allBooks: true,
        url: "",
    });

    useEffect(() => {
        deleteAllCookies();

        if (book === "all") {
            setState((prevState) => ({ ...prevState, allBooks: true }));
        } else {
            let result = book_details.filter((o) => o.book === book);
            setState((prevState) => ({
                ...prevState,
                book: result[0],
                allBooks: false,
                url: `https://twitter.com/intent/tweet?text=Check%20out%20this%20post%20on%20https://NOadsHERE.com/books/${book}`,
            }));
        }
        setState((prevState) => ({ ...prevState, books: book_details }));
    }, [book]);

    const deleteAllCookies = () => {
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

        localStorage.clear();
        sessionStorage.clear();
    };

    const filterArray = (e) => {
        var searched = book_details.filter(({ book }) =>
            book.includes(e.target.value.toLowerCase())
        );
        setState((prevState) => ({ ...prevState, books: searched }));
    };

    return (
        <div className="divmor">
            <Container>
                <Row className="movieRow">
                    {!state.allBooks && (
                        <div>
                            <Col className="" sm={12} lg={8}>
                                <div className="movieDiv">
                                    <h1 className="darkGrey">
                                        {state.book.name}
                                        <br />
                                        <span className="movieDate">
                                            {state.book.date} -{" "}
                                            {state.book.reviewer.twitter && (
                                                <a
                                                    href={state.book.reviewer.twitter}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    {state.book.reviewer.name}
                                                </a>
                                            )}
                                            {!state.book.reviewer.twitter &&
                                                state.book.reviewer.name}
                                        </span>
                                    </h1>
                                    <figure>
                                        <img
                                            className="movieImage"
                                            src={require(`../../assets/images/tv/${state.book.image_1}`).default}
                                            alt={state.book.image_1.text}
                                        />
                                        <figcaption className="fig">
                                            <h4>{state.book.image_1.text}</h4>
                                        </figcaption>
                                    </figure>
                                    <br />
                                    <p className="movieBlurb">{state.book.paragraph_1}</p>
                                    <p className="movieBlurb">{state.book.paragraph_2}</p>
                                    <br />
                                </div>
                            </Col>
                        </div>
                    )}
                    {state.allBooks && (
                        <div>
                            <Col className="" sm={12} lg={12}>
                                <div className="movieDiv">
                                    <Row>
                                        <Col className="" sm={4} lg={8}>
                                            <h1 className="darkGrey">Books</h1>
                                            <h4>We've finally added a book review section!</h4>
                                            <br />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="" sm={4} lg={4}>
                                            <Form.Group className="">
                                                <Form.Control
                                                    className=""
                                                    type="input"
                                                    id="searchId"
                                                    placeHolder="Search books"
                                                    onChange={filterArray}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="allRow">
                                        {Array.from(state.books).map((item) => (
                                            <Col className="tvCol" sm={4} lg={4}>
                                                <div
                                                    className="bgImageDiv"
                                                    style={{
                                                        backgroundImage: `url(${require(`../../assets/images/tv/${item.image_1}`).default})`,
                                                        backgroundSize: "cover",
                                                    }}
                                                >
                                                    <Card className="contentCard tv" key={item.name}>
                                                        {!item.darkTitle && (
                                                            <a
                                                                className="cardLink"
                                                                href={`/books/${item.book}`}
                                                            >
                                                                <p className="cardLinkText">{item.name}</p>
                                                            </a>
                                                        )}
                                                        {item.darkTitle && (
                                                            <a
                                                                className="cardLink"
                                                                href={`/books/${item.book}`}
                                                            >
                                                                <p className="cardLinkTextDark">{item.name}</p>
                                                            </a>
                                                        )}
                                                    </Card>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </Col>
                        </div>
                    )}
                </Row>
            </Container>
            <div className="footerAbout">
                <a className="footerLinks" href="/">
                    home
                </a>
                <a className="footerLinks" href="/tv/all">
                    tv
                </a>
                <a className="footerLinks" href="/movies/all">
                    movies
                </a>
                <a className="footerLinks" href="/recipes/all">
                    recipes
                </a>
                <a className="footerLinks" href="/about/">
                    about
                </a>
            </div>
        </div>
    );
}

export default Books;
