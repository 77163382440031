import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from './containers/Home/Home';
import Construction from './containers/Construction/Construction';
import About from './containers/About/About';
import Movies from './containers/Movies/Movies';
import Recipes from './containers/Recipes/Recipes';
import TV from './containers/TV/TV';
import Books from './containers/Books/Books';

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/construction" element={<Construction />} />
            <Route path="/movies/:movie" element={<Movies />} />
            <Route path="/movies/" element={<Movies />} />
            <Route path="/recipes/:recipe" element={<Recipes />} />
            <Route path="/recipes/" element={<Recipes />} />
            <Route path="/tv/:tv" element={<TV />} />
            <Route path="/tv/" element={<TV />} />
            <Route path="/books/:book" element={<Books />} />
            <Route path="/books/" element={<Books />} />
        </Routes>
    );
}
