import React, { useState, useEffect } from "react";
import { useParams /*, useNavigate*/ } from "react-router-dom";
import "../Home/Home.css";
import "../About/About.css";
import "./TV.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { tv_details } from '../../assets/tv/tv_details';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import twitter from '../../assets/images/twitter.png';
import { FacebookIcon, FacebookShareButton } from "react-share";

function TV() {
    const { tv } = useParams();  // Get the movie param from the route

    const [state, setState] = useState({
        tv: {
            "name": "White Men Can't Jump Review",
            "tv": "white-men-cant-jump-review",
            "subtitle": "A lot of fun",
            "rating": 5,
            "image_1": { image: "WMCJ-15190.jpg", text: "", alt: "" },
            "image_2": { image: "dune001.jpg", text: "" },
            "image_3": "",
            "image_4": "",
            "image_5": "",
            "image_6": "",
            "paragraph_1": "Dune is an epic sci-fi movie based on a famous 1965 book",
            "paragraph_2": () => <span>Test</span>,
            "paragraph_3": "",
            "paragraph_4": "",
            "paragraph_5": "",
            "paragraph_6": "",
            "diversity": {},
            "date": "13th November '21",
            "allMovies": true,
            "image_sources": "",
            "reviewer": { name: "Test", twitter: "test twitter" },
        },
        movies: [],
        url: "",
        allMovies: true,
        loadedImages: {}, // Store dynamic image imports
    });

    useEffect(() => {
        deleteAllCookies();

        if (tv === "all") {
            setState(prevState => ({ ...prevState, allMovies: true }));
            // Load images for all movies
            loadAllMovieImages(tv_details);
        } else {
            const result = tv_details.filter(o => o.tv === tv);
            if (result.length > 0) {
                setState(prevState => ({
                    ...prevState,
                    tv: result[0],
                    allMovies: false,
                    url: `https://twitter.com/intent/tweet?text=Check%20out%20this%20post%20on%20https://NOadsHERE.com/movies/${tv}`,
                }));
                // Load main images for the specific movie
                loadImages(result[0]);
            }
        }
        setState(prevState => ({ ...prevState, movies: tv_details }));
    }, [tv]);

    const loadImages = (tv) => {
        const imageKeys = ['image_1', 'image_2', 'image_3', 'image_4', 'image_5', 'image_6'];
        imageKeys.forEach((key) => {
            if (tv[key] && tv[key].image) {
                import(`../../assets/images/tv/${tv[key].image}`)
                    .then((image) => {
                        console.log('Loaded image:', image.default); // Debugging
                        setState(prevState => ({
                            ...prevState,
                            loadedImages: { ...prevState.loadedImages, [key]: image.default }
                        }));
                    })
                    .catch((err) => console.error("Error loading image:", err));
            }
        });
    };

    const loadAllMovieImages = (movies) => {
        movies.forEach((tv) => {
            if (tv.image_1 && tv.image_1.image) {
                import(`../../assets/images/tv/${tv.image_1.image}`)
                    .then((image) => {
                        setState(prevState => ({
                            ...prevState,
                            loadedImages: {
                                ...prevState.loadedImages,
                                [tv.tv]: image.default // Use movie identifier to store image
                            }
                        }));
                    })
                    .catch((err) => console.error("Error loading image:", err));
            }
        });
    };

    const deleteAllCookies = () => {
        const cookies = document.cookie.split(";");
        cookies.forEach(cookie => {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        });
        localStorage.clear();
        sessionStorage.clear();
    };

    const filterArray = (e) => {
        const searched = tv_details.filter(({ tv }) => tv.includes(e.target.value.toLowerCase()));
        setState(prevState => ({ ...prevState, movies: searched }));
    };

    return (
        <div className="divmor">
            <Container>
                <Row className="movieRow">
                    {!state.allMovies && (
                        <div>
                            <Col sm={12} lg={8}>
                                <div className="movieDiv">
                                    <h1 className="darkGrey">
                                        {state.tv.name}<br />
                                        {state.tv.subtitle && <p className="subTitle">{state.tv.subtitle}</p>}
                                        <span className="movieDate">{state.tv.date} - {state.tv.reviewer.twitter && <a href={state.tv.reviewer.twitter} rel="noopener noreferrer" target="_blank">{state.tv.reviewer.name}</a>}
                                            {!state.tv.reviewer.twitter && state.tv.reviewer.name}
                                        </span>
                                    </h1>
                                    {/* Main Movie Image */}
                                    {state.loadedImages.image_1 && (
                                        <img className="movieImage"
                                             src={state.loadedImages.image_1}
                                             alt={state.tv.image_1.text} />
                                    )}
                                    <figcaption className="fig"><h4>{state.tv.image_1.text}</h4></figcaption>
                                    <br />
                                    <p className="movieBlurb">{state.tv.paragraph_1}</p>
                                    <p className="movieBlurb">{state.tv.paragraph_2()}</p>
                                    {state.loadedImages["image_2"] && (
                                        <div>
                                            <br />
                                            <img className="movieImage"
                                                 src={state.loadedImages["image_2"]}
                                                 alt={state.tv["image_2"].text} />
                                            <figcaption className="fig"><h4>{state.tv["image_2"].text}</h4></figcaption>
                                            <br />
                                        </div>
                                    )}
                                    <p className="movieBlurb">{state.tv.paragraph_3}</p>
                                    <p className="movieBlurb">{state.tv.paragraph_4}</p>
                                    {state.loadedImages["image_3"] && (
                                        <div>
                                            <br />
                                            <img className="movieImage"
                                                 src={state.loadedImages["image_3"]}
                                                 alt={state.tv["image_3"].text} />
                                            <figcaption className="fig"><h4>{state.tv["image_3"].text}</h4></figcaption>
                                            <br />
                                        </div>
                                    )}
                                    <p className="movieBlurb">{state.tv.paragraph_5}</p>
                                    {state.loadedImages["image_4"] && (
                                        <div>
                                            <br />
                                            <img className="movieImage"
                                                 src={state.loadedImages["image_4"]}
                                                 alt={state.tv["image_4"].text} />
                                            <figcaption className="fig"><h4>{state.tv["image_4"].text}</h4></figcaption>
                                            <br />
                                        </div>
                                    )}
                                    <p className="movieBlurb">{state.tv.paragraph_6}</p>
                                    <p className="movieBlurb">{state.tv.paragraph_7}</p>
                                    <p className="movieBlurb">{state.tv.paragraph_8}</p>
                                    <p className="movieBlurb">{state.tv.paragraph_9}</p>
                                    <p className="movieBlurb"><strong>Rating:</strong> {state.tv.rating} stars</p>
                                    <p className="movieBlurb sources">All images courtesy of: <a href={state.tv.image_sources} rel="noopener noreferrer" target="_blank">{state.tv.image_sources}</a> </p>

                                    <p className="movieBlurb"><strong>Share this post on:</strong></p>
                                    <a className="twitter-share-button" rel="noopener noreferrer" target="_blank"
                                       href={state.url + state.tv.tv}>
                                        <img className="social shareImage" src={twitter} alt="Twitter logo"/></a>
                                    <br/>
                                    <br/>
                                    <FacebookShareButton
                                        url={'https://www.noadshere.com/tv/' + state.tv.tv}
                                        quote={'Check out this review!'}
                                        hashtag="#NOadsHERE"
                                    >
                                        <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                    <br/>
                                    <br/>

                                </div>
                            </Col>
                        </div>
                    )}
                    {state.allMovies && (
                        <div>
                            <Col sm={12} lg={12}>
                                <div className="movieDiv">
                                    <Row>
                                        <Col sm={4} lg={8}>
                                            <h1 className="darkGrey">TV Reviews</h1>
                                            <h4>We should probably call this Streamers! A page for new
                                                (and some old favourite) "television" shows. We'll mark any posts that
                                                have spoilers, so read with confidence!</h4>
                                            <br />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4} lg={4}>
                                            <Form.Group>
                                                <Form.Control type="input" id="searchId" placeholder="Search movies" onChange={filterArray} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="allRow">
                                        {state.movies.map((item) => (
                                            <Col className="tvCol" sm={4} lg={4} key={item.tv}>
                                                <div className="bgImageDiv" style={{ backgroundImage: state.loadedImages[item.tv] ? `url(${state.loadedImages[item.tv]})` : 'none', backgroundSize: "cover" }}>
                                                    <Card className="contentCard tv">
                                                        <a className="cardLink" href={`/tv/${item.tv}`}>
                                                            <p className="cardLinkText">{item.name}</p>
                                                        </a>
                                                    </Card>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </Col>
                        </div>
                    )}
                </Row>
            </Container>
            <div className="footerAbout">
                <a className="footerLinks" href="/">home</a>
                <a className="footerLinks" href="/tv/all">tv</a>
                <a className="footerLinks" href="/movies/all">movies</a>
                <a className="footerLinks" href="/recipes/all">recipes</a>
                <a className="footerLinks" href="/about/">about</a>
            </div>
        </div>
    );
}

export default TV;
