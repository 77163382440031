import React, { useState, useEffect } from "react";
import { useParams /*, useNavigate*/ } from "react-router-dom";
import "../Home/Home.css";
import "../About/About.css";
import "./Movies.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { movie_details } from '../../assets/movies/movie_details';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import twitter from '../../assets/images/twitter.png';
import { FacebookIcon, FacebookShareButton } from "react-share";

function Movies() {
    const { movie } = useParams();  // Get the movie param from the route

    const [state, setState] = useState({
        movie: {
            "name": "White Men Can't Jump Review",
            "movie": "white-men-cant-jump-review",
            "subtitle": "A lot of fun",
            "rating": 5,
            "image_1": { image: "WMCJ-15190.jpg", text: "", alt: "" },
            "image_2": { image: "dune001.jpg", text: "" },
            "image_3": "",
            "image_4": "",
            "image_5": "",
            "image_6": "",
            "paragraph_1": "Dune is an epic sci-fi movie based on a famous 1965 book",
            "paragraph_2": () => <span>Test</span>,
            "paragraph_3": "",
            "paragraph_4": "",
            "paragraph_5": "",
            "paragraph_6": "",
            "diversity": {},
            "date": "13th November '21",
            "allMovies": true,
            "image_sources": "",
            "reviewer": { name: "Test", twitter: "test twitter" },
        },
        movies: [],
        url: "",
        allMovies: true,
        loadedImages: {}, // Store dynamic image imports
    });

    useEffect(() => {
        deleteAllCookies();

        if (movie === "all") {
            setState(prevState => ({ ...prevState, allMovies: true }));
            // Load images for all movies
            loadAllMovieImages(movie_details);
        } else {
            const result = movie_details.filter(o => o.movie === movie);
            if (result.length > 0) {
                setState(prevState => ({
                    ...prevState,
                    movie: result[0],
                    allMovies: false,
                    url: `https://twitter.com/intent/tweet?text=Check%20out%20this%20post%20on%20https://NOadsHERE.com/movies/${movie}`,
                }));
                // Load main images for the specific movie
                loadImages(result[0]);
            }
        }
        setState(prevState => ({ ...prevState, movies: movie_details }));
    }, [movie]);

    const loadImages = (movie) => {
        const imageKeys = ['image_1', 'image_2', 'image_3', 'image_4', 'image_5', 'image_6'];
        imageKeys.forEach((key) => {
            if (movie[key] && movie[key].image) {
                import(`../../assets/images/${movie[key].image}`)
                    .then((image) => {
                        setState(prevState => ({
                            ...prevState,
                            loadedImages: { ...prevState.loadedImages, [key]: image.default }
                        }));
                    })
                    .catch((err) => console.error("Error loading image:", err));
            }
        });
    };

    const loadAllMovieImages = (movies) => {
        movies.forEach((movie) => {
            if (movie.image_1 && movie.image_1.image) {
                import(`../../assets/images/${movie.image_1.image}`)
                    .then((image) => {
                        setState(prevState => ({
                            ...prevState,
                            loadedImages: {
                                ...prevState.loadedImages,
                                [movie.movie]: image.default // Use movie identifier to store image
                            }
                        }));
                    })
                    .catch((err) => console.error("Error loading image:", err));
            }
        });
    };

    const deleteAllCookies = () => {
        const cookies = document.cookie.split(";");
        cookies.forEach(cookie => {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        });
        localStorage.clear();
        sessionStorage.clear();
    };

    const filterArray = (e) => {
        const searched = movie_details.filter(({ movie }) => movie.includes(e.target.value.toLowerCase()));
        setState(prevState => ({ ...prevState, movies: searched }));
    };

    return (
        <div className="divmor">
            <Container>
                <Row className="movieRow">
                    {!state.allMovies && (
                        <div>
                            <Col sm={12} lg={8}>
                                <div className="movieDiv">
                                    <h1 className="darkGrey">
                                        {state.movie.name}<br />
                                        {state.movie.subtitle && <p className="subTitle">{state.movie.subtitle}</p>}
                                        <span className="movieDate">{state.movie.date} - {state.movie.reviewer.twitter && <a href={state.movie.reviewer.twitter} rel="noopener noreferrer" target="_blank">{state.movie.reviewer.name}</a>}
                                            {!state.movie.reviewer.twitter && state.movie.reviewer.name}
                                        </span>
                                    </h1>
                                    {/* Main Movie Image */}
                                    {state.loadedImages.image_1 && (
                                        <img className="movieImage"
                                             src={state.loadedImages.image_1}
                                             alt={state.movie.image_1.text} />
                                    )}
                                    <figcaption className="fig"><h4>{state.movie.image_1.text}</h4></figcaption>
                                    <br />
                                    <p className="movieBlurb">{state.movie.paragraph_1}</p>
                                    <p className="movieBlurb"><strong>Director: </strong>{state.movie.director}</p>
                                    <p className="movieBlurb"><strong>Main cast: </strong>{state.movie.main_cast}</p>

                                    <p className="movieBlurb">{state.movie.paragraph_2()}</p>
                                    <p className="movieBlurb">{state.movie.paragraph_3}</p>
                                    {
                                        state.loadedImages["image_2"] && (
                                            <div>
                                                <br />
                                                <img className="movieImage"
                                                     src={state.loadedImages["image_2"]}
                                                     alt={state.movie["image_2"].text} />
                                                <figcaption className="fig"><h4>{state.movie["image_2"].text}</h4></figcaption>
                                                <br />
                                            </div>
                                        )
                                    }
                                    <p className="movieBlurb">{state.movie.paragraph_4}</p>
                                    <p className="movieBlurb">{state.movie.paragraph_5}</p>
                                    {
                                        state.loadedImages["image_3"] && (
                                            <div>
                                                <br />
                                                <img className="movieImage"
                                                     src={state.loadedImages["image_3"]}
                                                     alt={state.movie["image_3"].text} />
                                                <figcaption className="fig"><h4>{state.movie["image_3"].text}</h4></figcaption>
                                                <br />
                                            </div>
                                        )
                                    }
                                    <p className="movieBlurb">{state.movie.paragraph_6}</p>
                                    {
                                        state.loadedImages["image_4"] && (
                                            <div>
                                                <br />
                                                <img className="movieImage"
                                                     src={state.loadedImages["image_4"]}
                                                     alt={state.movie["image_4"].text} />
                                                <figcaption className="fig"><h4>{state.movie["image_4"].text}</h4></figcaption>
                                                <br />
                                            </div>
                                        )
                                    }

                                    <p className="movieBlurb">{state.movie.paragraph_7}</p>
                                    <p className="movieBlurb"><strong>Summary:</strong> {state.movie.summary}</p>
                                    <p className="movieBlurb"><strong>Rating:</strong> {state.movie.rating} stars</p>
                                    <p className="movieBlurb endBit sources">All images courtesy of: <a href={state.movie.image_sources} rel="noopener noreferrer" target="_blank">{state.movie.image_sources}</a> </p>


                                </div>
                            </Col>

                            {/* Render Diversity Section */}
                            <Col className="diversityCol" sm={12} lg={4}>
                                <div className="diversityDiv">
                                    <p className="leftText movieBlurb"><strong>Diverse or not diverse?</strong></p>

                                    {Array.from(state.movie.diversity).map((item) => (
                                        <Card className={item.className} key={item.question}>
                                            <p className="movieBlurb"><strong>{item.question}</strong></p>
                                            <p className="movieBlurb">{item.answer}</p>
                                        </Card>
                                    ))}
                                </div>
                                <h3 className="shareText"><strong>Share this post on:</strong></h3>
                                <a className="twitter-share-button" rel="noopener noreferrer" target="_blank"
                                   href={state.url + state.movie.movie}>
                                    <img className="social floatLeft" src={twitter} alt="Twitter logo" />
                                </a>
                                <br />
                                <br />
                                <FacebookShareButton
                                    className="facebookButton"
                                    url={'https://www.noadshere.com/movies/' + state.movie.movie}
                                    quote={'Check out this review!'}
                                    hashtag="#NOadsHERE"
                                >
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>
                                <br />
                                <br />
                            </Col>
                        </div>
                    )}
                    {state.allMovies && (
                        <div>
                            <Col sm={12} lg={12}>
                                <div className="movieDiv">
                                    <Row>
                                        <Col sm={4} lg={8}>
                                            <h1 className="darkGrey">Movie Reviews</h1>
                                            <h4>We couldn't build a site like this without movie reviews. Some are new releases, some are movies we come across on the streamers. For all movies we give an honest opinion and no spoilers! We also have a 'Diverse or not Diverse' section for each movie!</h4>
                                            <br />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={4} lg={4}>
                                            <Form.Group>
                                                <Form.Control type="input" id="searchId" placeholder="Search movies" onChange={filterArray} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="allRow">
                                        {state.movies.map((item) => (
                                            <Col className="tvCol" sm={4} lg={4} key={item.movie}>
                                                <div className="bgImageDiv" style={{ backgroundImage: state.loadedImages[item.movie] ? `url(${state.loadedImages[item.movie]})` : 'none', backgroundSize: "cover" }}>
                                                    <Card className="contentCard tv">
                                                        <a className="cardLink" href={`/movies/${item.movie}`}>
                                                            <p className="cardLinkText">{item.name}</p>
                                                        </a>
                                                    </Card>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </Col>
                        </div>
                    )}
                </Row>
            </Container>
            <div className="footerAbout">
                <a className="footerLinks" href="/">home</a>
                <a className="footerLinks" href="/tv/all">tv</a>
                <a className="footerLinks" href="/movies/all">movies</a>
                <a className="footerLinks" href="/recipes/all">recipes</a>
                <a className="footerLinks" href="/about/">about</a>
            </div>
        </div>
    );
}

export default Movies;
